import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { Button, styled } from '@mui/material'
import { Home, Lock, Person } from '@mui/icons-material'
import { isEmpty } from 'lodash'

import { useAppContext } from '../contexts/appContext'
import { routePaths } from '../constants/routes'
import { useProfileContext } from '../contexts/profileContext'
import SEO from '../components/common/seo'
import Header from '../components/common/Header'
import { SubContainer } from '../components/common/Styled'
import { Field, Form } from 'react-final-form'
import PasswordField from '../components/common/PasswordField'

const FormContainer = styled('form')`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 20px 100px;
  @media (max-width: 600px) {
    padding: 24px 16px;
    width: 100%;
    height: auto;
  }
`

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
  }
`

const title = 'Changement du mot de passe'

const PasswordPage = () => {
  const { home } = useStaticQuery(query)
  const showSnackbar = useAppContext()?.showSnackbar
  const updatePassword = useProfileContext()?.updatePassword

  const handleSubmit = async values => {
    const { newPassword, currentPassword } = values
    try {
      await updatePassword({
        currentPassword,
        newPassword
      })
      showSnackbar('success', 'Mot de passe mit à jour')
      navigate(routePaths.profile)
    } catch (e: any) {
      if (e.message === 'wrong-current-password')
        showSnackbar('error', 'Mot de passe incorrect')
      else showSnackbar('error', 'Une erreur est survenue, veuillez reessayer')
    }
  }

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <Person />,
      label: 'Mon compte',
      route: routePaths.profile
    },
    {
      icon: <Lock />,
      label: title
    }
  ]

  return (
    <>
      <SEO title="Mot de passe" />
      <SubContainer>
        <Header {...{ title, items }} />
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            currentPassword: '',
            newPassword: '',
            passwordConfirmation: ''
          }}
          validate={values => {
            const errors: any = {}
            if (!values.currentPassword) errors.currentPassword = 'required'
            if (!values.newPassword) errors.newPassword = 'required'
            if (!values.passwordConfirmation)
              errors.passwordConfirmation = 'required'
            if (values.newPassword !== values.passwordConfirmation) {
              errors.passwordConfirmation = 'must-be-equal'
              errors.newPassword = 'must-be-equal'
            }
            return errors
          }}
          render={({ handleSubmit, errors, submitting }) => (
            <FormContainer onSubmit={handleSubmit}>
              <Field
                name="currentPassword"
                label="Mot de passe actuel"
                render={PasswordField}
                autoFocus
              />
              <Field
                name="newPassword"
                label="Nouveau mot de passe"
                render={PasswordField}
                helperText="Les mots de passe doivent être identiques"
              />
              <Field
                name="passwordConfirmation"
                label="Confirmation du mot de passe"
                render={PasswordField}
                helperText="Les mots de passe doivent être identiques"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isEmpty(errors) || submitting}
              >
                Mettre à jour
              </Button>
            </FormContainer>
          )}
        />
      </SubContainer>
    </>
  )
}

export default PasswordPage
